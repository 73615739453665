<template>
    <div class="container">
         <b-row class=" no-gutters height-self-center">
            <b-col sm="12" class=" text-center align-self-center">
               <div class="iq-error position-relative">
                  <h3>Error 401</h3>
                  <h3>You are not authorized! 🔐</h3>
                  <h4 class="mb-0 mt-4">You do not have permission to access this page. Go Home!</h4>
                  <router-link class="btn btn-primary d-inline-flex align-items-center mt-3" :to="{name: 'layout.order'}"><i class="ri-home-4-line"></i>Back to Home</router-link>
               </div>
            </b-col>
         </b-row>
   </div>
</template>
<script>
export default {
    name:'Error401',
    data(){
       return{
          image: require('../../../../assets/images/error/Datum_404.png')
       }
    }
}
</script>